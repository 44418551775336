import React, {useEffect} from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import moduleConductor from "../modules/module-conductor";

const InsightsPage = ({ data }) => {
  const page = data.contentfulTemplateListingPage;
  const modules = page.addPageModules;

  const metaTitle = page?.seoPageTitle || page?.pageTitle;
	const metaDesc = page.pageDescription
		? page.pageDescription.pageDescription
		: null;
	const metaImage = page?.seoShareImage?.file?.url;
	useEffect(() => {
        const obj = { url : window.location.href }

        if (page.slug === "news-and-events" || page.slug === "news") {
          obj.contentType = "news"
          obj.pageType = "section"
          obj.pageTitle = "News"
          obj.sbu= "ULX"
        } else if (page.slug === "events") {
          obj.contentType = "events"
          obj.pageType = "section"
          obj.pageTitle = "Events"
          obj.sbu= "ULX"
        } else if(page.slug==="insights") {
          obj.contentType = "insights"
          obj.pageType = "section"
          obj.pageTitle = "Insights"
          obj.sbu= "ULX"
          obj.contentPublicationDate = "2022-03-18T08:06:38Z"
          obj.timestamp = "2022-03-18T15:23:44"
          obj.form= "no"
        }
        window?.dataLayer?.push(obj);
    },[page.slug])

  return (
    <Layout isSidebar="contact" isWhiteNav={true} additionalClassName={`${page.slug}`}>
      <SEO title={`${metaTitle} | UnitedLex`} description={metaDesc} image={metaImage} />
      { modules.map((module, index) => moduleConductor(module, index)) }
    </Layout>
  );
}

export default InsightsPage;

export const pageQuery = graphql`
	query($slug: String!) {
		contentfulTemplateListingPage(slug: { eq: $slug }) {
      slug
      pageTitle
      doNotIndex
			pageDescription {
				pageDescription
			}
      seoPageTitle
			seoShareImage {
				...ContentfulAssetFragment
			}
      addPageModules {
        __typename
        ... on ContentfulModuleInsightsHeader {
          title
          content {
            content
          }
          imageDesktop {
            file {
              url
            }
          }
          imageMobile {
            file {
              url
            }
          }
        }
        ... on ContentfulModuleFeaturedInsights {
          title
          mainFeature {
            insightTitle
            slug
            publishDate
            previewExcerpt {
              previewExcerpt
            }
            mainImage {
              file {
                url
              }
            }
            insightType {
              insightType
            }
            newsSource
          }
          featuredList {
            insightTitle
            slug
            insightType {
              insightType
            }
            newsSource
          }
        }
        ... on ContentfulModuleMostReadInsights {
          title
          insights {
            id
            insightTitle
            slug
            insightType {
              insightType
            }
            newsSource
          }
          featuredEyebrow
          featuredTitle
          featuredContent
          featuredCta
          featuredCtaDestination
          featuredImage {
            file {
              url
            }
          }
        }
        ... on ContentfulModuleAllInsights {
          title
          buttonLoadMore
          categories {
            insightType
          }
          solutions {
            id
            pageTitle
          }
        }
        
        ...ModuleExpandingCardsFragment
        ...ModuleTwoColumnCardsFragment
        ...ModuleFeaturesCardsFragment
        ...ModuleCtaBannerFragment
        ...ModulePullQuotesFragment
        ...ModuleCarouselFragment
        ...ModuleRelatedContentFragment
        ...ModuleLocationsMapContentfulFragment
        ...ModuleInsightBannerFragment
        ...ModuleFeaturedBannerFragment
        ...ModuleSimpleSectionHeadingFragment
      }
		}
	}
`;
